import getBaseConfig from '../baseConfig-2.0.0.js';
async function initApplication() {
    try {
        // 执行异步请求，例如获取配置信息、用户状态等
        //   const config = await fetchConfig();

        const config = await getBaseConfig(window.baseConfig, process.env.VUE_APP_BASE_API);
        window.baseConfig = config;
        console.log("config====", config);
        // 确保配置成功获取后再初始化 Vue 应用
        import('./app-init').then(() => {
            console.log("Vue 应用初始化成功。");
        });
    } catch (error) {
        console.error("初始化 Vue 应用失败：", error);
        alert("初始化 Vue 应用失败:" + error)
    }
}

// 执行初始化函数
initApplication();
